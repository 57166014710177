<template>
  <div>
    <NavBar :list="list" name="智能电表管理"></NavBar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          path: "/meter/meterManage",
          name: "智能电表管理",
        },
        {
          path: "/meter/rechargeRecord",
          name: "充值记录",
        },
        // {
        //   path: "/meter/electricTotal",
        //   name: "用电统计",
        // },
        // {
        //   path: "/meter/clearRecord",
        //   name: "清零记录",
        // },
        // {
        //   path: "/meter/merchantElectricTotal",
        //   name: "商户用电统计",
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
