<template>
  <div>
    <NavBar :list="list" name="智能水表管理"></NavBar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          path: "/meterWater/meterManage",
          name: "智能水表管理",
        },
        {
          path: "/meterWater/rechargeRecord",
          name: "充值记录",
        },
        // {
        //   path: "/meterWater/waterTotal",
        //   name: "用水统计",
        // },
        // {
        //   path: "/meterWater/clearRecord",
        //   name: "清零记录",
        // },
        // {
        //   path: "/meterWater/merchantWaterTotal",
        //   name: "商户用水统计",
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
